div:has(> .about):before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 1260px;
    width: 100%;
    /* aspect-ratio: 1/1; */
    overflow: hidden;
    opacity: 0.4;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    background-image: url('../img/altBackground.jpg');
    z-index: -4;
}

.section {
    height: fit-content;
    /* border: 1px solid #444444; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    padding: 30px 0px;
    margin: 0 40px;
}

.section h3 {
    font-size: 25px;
    text-align: center;
}
.section p {
    font-size: 18px;
    width: 400px;
    text-align: justify;
}
.section li {
    margin-bottom: 3px;
}
ul {
    max-width: 380px;
    padding-left: 20px;
}

.abtPic {
    aspect-ratio: 1/1;
    height: 400px;
    /* margin: 80px; */
}

.skill {
    text-align: center;
    margin: auto;
    width: fit-content;
}
.skill p {
    width: fit-content;
    margin: 0;
}