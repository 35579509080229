
body {
    margin: 0;
    padding: 0;
    background-color: #e1e1e1;
}


button {
    border-radius: 5px;
    background: none;
    color: inherit;
    border: 2px outset #e1e1e1;
    font-size: 1.1em;
    font-family: inherit;
    padding: 5px 10px;
    margin: 20px;
}

h2 {
    font-size: 3em;
}

h1 {
    font-size: 4em;
}

p {
    font-size: 1.5em;
    font-weight: 200;
}


/* @media screen and (max-width: 875px) {
    .hero {
        height: 815px;
        padding-top: 120px;
    }

    .hero h2 {
        display: none;
    }
} */