div:has(> .home):before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 1360px;
    width: 100%;
    /* aspect-ratio: 1/1; */
    overflow: hidden;
    opacity: 0.4;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    background-image: url('../img/heroBackground.jpg');
    z-index: -4;
}

main {
    padding-top: 80px;
}


.flex {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-evenly;
    width: 100%;
    align-items: flex-end;
}

.hero {
    color: black;
    /* -webkit-text-stroke: 1px #444444; */
    padding: 0px;
    margin: 0;
    /* margin-top: 120px; */
    /* border-bottom: 3px solid #000000; */
    /* text-shadow: 2px 2px 3px #000000; */
    font-weight: lighter;
    font-family: fantasy;
    height: 620px;
}


.heroPic {
    aspect-ratio: 1 / 1;
    width: 350px;
}

.carousel {
    position: relative;
    /* border: 1px solid #444444; */
    width: 60%;
    aspect-ratio: 1151/751;
    margin: 30px auto;
    overflow: hidden;
    cursor: pointer;
}

.slide {
    width: 100%;
    position: absolute;
    transition: all 0.5s;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.buttonContainer { 
    background-color: #444444a6;
    background: linear-gradient(0deg, rgba(68,68,68,0.7320115546218487) 52%, rgba(68,68,68,0.2474177170868347) 75%, rgba(68,68,68,0) 100%);
    margin: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 80px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel button {
    background-color: #e1e1e1;
    /* position: absolute; */
    bottom: 10px;
    z-index: 10;
}
.carousel button:active {
    transform: scale(1.1);
}
.quickBtn {
    border-radius: 25px;
    width: 5px;
    padding: 5px;
    margin: 10px;
}
.quickBtn.current {
    background-color: #444444;
}