header {
    padding: 20px;
    position: fixed;
    height: 40px;
    width: 100vw;
    top: 0;
    display: flex;
    align-items: center;
    background-color: #e1e1e1d8;
}

header a {
    margin: 30px;
    font-size: 1.5em;
    color: #000000;
    /* -webkit-text-stroke: 2px #444444; */
}
