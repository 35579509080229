:root {
 --off-white: #e1e1e1;
 --dark-grey: #444444;
}

/* ::-webkit-scrollbar { 
  width: 10px; 
}  */

body {
  font-family: fantasy;
}

a {
  color: var(--dark-grey);
  text-decoration: none;
}