div:has(> .portfolio):before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 1020px;
    width: 100%;
    /* aspect-ratio: 1/1; */
    overflow: hidden;
    opacity: 0.4;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    background-image: url('../img/portBackground.jpg');
    z-index: -4;
}
@media only screen and (max-width: 1215px) {
    div:has(> .portfolio):before {
      height: 1450px;
    }
  }


.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.projectCard {
    width: 500px;
    aspect-ratio: 1151/751;
    margin: 50px;
    position: relative;
    overflow: hidden;
}
.projectCard div {
    position: absolute;
    z-index: 1;
    background-color: #e1e1e17a;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000;
}
.projectCard div h3 {
    font-size: 40px;
}
.projectCard div p {
    font-size: 25px;
}
.projectCard img {
    width: 100%;
    position: absolute;
}